@use '../theme-variables.module.scss' as *;

$uploadHeaderHeight: 50px;
$uploadPaginationHeight: 50px;
$uploadGridGap: 10px;

.upload-containter {
    display: grid;
    grid-template-columns: minmax(250px, 1fr) minmax(400px, 4fr);
    grid-template-rows: $uploadHeaderHeight 1fr $uploadPaginationHeight;

    height: calc(100vh - $headerHeight - 2 * $gridGap );
    width: calc(100vw - $sideBarWidth - 2 * $gridGap );
    grid-gap: $uploadGridGap;

}

.upload-header {
    background-color:  var(--whiteColor);
    grid-area: 1/1/2/3;
    display: flex;
    align-items: center;
}

.upload-filter {
    background-color:  var(--whiteColor);
    grid-area: 2/1/3/2;
    height: calc(100vh - $headerHeight - 2 * $gridGap - 2 * $uploadGridGap - 100px);

}
.close-btn{
    position: absolute;
    right: 0;
}

.upload-group-list {
    background-color:  var(--whiteColor);
    grid-area: 2/2/3/3;
}

.upload-pagination {
    grid-area: 3/2/4/3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-title-wrapper {
    margin: 1rem 0rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 950px;

    .title {
        font-size: 1.15rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

    }
}

.file-upload-container {
    padding: 0.875rem;
    background-color: var(--secondaryMain);
    border: 1px dashed var(--primaryMain);
    // width: fit-content;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .title {
        color: var(--blackColor);
        font-size: 1.063rem;
        font-style: normal;
        font-weight: 400;

        span {
            cursor: pointer;
            color: var(--primaryMain);
            font-size: 1.063rem;
            font-style: normal;
            font-weight: 600;
            text-decoration-line: underline;
        }
    }

    .sub-title {
        margin-top: 0.3rem;
        color: var(--subtitleGrey);
        font-size: 0.813rem;
        font-style: normal;
        font-weight: 400;
    }
}

.download-template {
    color: var(--subtitleGrey);
    font-size: 0.813rem;
    font-style: normal;
    font-weight: 400;
    padding: 0.4rem 0;

    span {
        cursor: pointer;
        color: var(--primaryMain);
        font-size: 0.813rem;
        font-style: normal;
        font-weight: 700;
        text-decoration-line: underline;
    }
}
.date-icon-wrapper {
    padding-left: 2.5rem !important;
    display: flex;
    // flex-direction: column;
    justify-content: space-between;
    // justify-content: flex-start;
    align-items: center;

    .relative {
        position: relative;
        width: fit-content;
    }

    .close-icon {
        position: absolute;
        right: -7px;
        top: -8px;
        cursor: pointer;
    }
}

.file-list-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.filter-txt {
    margin-bottom: 0.5rem;
    color:var(--blackColor);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
}

.file-name {
    color: var(--blackColor);
    text-align: center;
    font-size: 0.813rem;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 9.5rem; 
}

.upload-container {
    height: calc(100vh - $headerHeight - 2 * $gridGap );
    overflow-x: hidden;
    overflow-y: scroll;
}

.status-desc{
    text-align: center;
    font-size: 0.875rem;
    color:var(--blackColor);
}

.history-search-fields{
    display: flex;
}